<template>
  <div class="map">
    <v-mapbox
      :access-token="mapboxToken"
      map-style="mapbox://styles/mapbox/light-v9"
      :center="[5.1, 52.05]"
      :zoom="11"
      :pitch="0"
      :bearing="0"
      ref="map"
      class="map"
      >
      <v-mapbox-navigation-control :options="{ visualizePitch: true }" />
      <map-control-baselayer
        :layers="baseLayers"
        position="bottom-right"
      />
      <mapbox-legend />
    </v-mapbox>
  </div>
</template>

<script>
import MapboxLegend from '@/components/MapboxMap/MapboxLegend'
import MapControlBaselayer from '@/components/MapboxMap/MapControlBaselayer'
import { mapMutations, mapActions } from 'vuex'
import _ from 'lodash'

const emptyFeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [0, 0]
      }
    }
  ]
}

const baseLayers = [
  'light-v10',
  'satellite-streets-v11',
  'streets-v11'
].map(layer => ({
  style: `mapbox://styles/mapbox/${layer}`,
  title: layer,
  thumbnail: `/thumbnails/${layer}.png`
}))

export default {
  name: 'map-component',
  data: () => {
    return {
      map: null,
      mapboxToken: process.env.VUE_APP_MAPBOX_TOKEN,
      baseLayers: baseLayers
    }
  },
  components: {
    MapboxLegend,
    MapControlBaselayer
  },
  watch: {
    '$store.state.dataLayers': {
      handler () {
        if (this.map !== null) {
          this.addLayers()
        }
      }
    }
  },
  mounted () {
    this.$refs.map.map.on('load', () => {
      this.map = this.$refs.map.map
      this.setMap(this.map)
      this.addLayers()
    })
  },
  methods: {
    ...mapMutations(['setMap']),
    ...mapActions(['loadLocationDetails']),
    addLayers () {
      if (!this.map.getLayer('custom-selected-location')) {
        this.map.addLayer({
          id: 'custom-selected-location',
          type: 'circle',
          source: {
            type: 'geojson',
            data: emptyFeatureCollection
          },
          paint: {
            'circle-color': 'red',
            'circle-radius': 5,
            'circle-stroke-color': '#004075',
            'circle-stroke-width': 5
          }
        })
      }
      this.$store.state.dataLayers.forEach((layer) => {
        layer.data.forEach((maplayer) => {
          if (!this.map.getLayer(maplayer.id)) {
            this.map.addLayer(maplayer, 'custom-selected-location')
            this.map.on('mouseover', maplayer.id, this.mouseOverFunction)
            this.map.on('mouseleave', maplayer.id, this.mouseLeaveFunction)
            this.map.on('click', maplayer.id, this.mouseClickFunction)
          }
        })
      })
    },
    mouseOverFunction () {
      // Create mouse pointer effect when hovering over a clickable layer
      this.map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeaveFunction () {
      // Create mouse pointer effect when hovering out a clickable layer
      this.map.getCanvas().style.cursor = ''
    },
    mouseClickFunction (e) {
      if (this.$router.currentRoute.path !== '/results') {
        this.$router.push('/results')
      }
      const locationId = _.get(e, 'features[0].properties.id')
      const features = this.map.queryRenderedFeatures(e.lngLat)
      console.log(features)
      const coords = [e.lngLat.lng, e.lngLat.lat]
      const FC = emptyFeatureCollection
      FC.features[0].geometry.coordinates = coords
      this.map.getSource('custom-selected-location').setData(FC)
      this.map.setCenter([e.lngLat.lng, e.lngLat.lat])
      this.loadLocationDetails(locationId)
    }
  }
}

</script>

<style>
@import '~mapbox-gl/dist/mapbox-gl.css';

.map {
  width: 100%;
  height: 100%;
}
</style>
