<template>
  <div class="echart">
    <v-chart :options="options" :autoresize="true" class="echart" ref="echart"/>
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markPoint'
import 'echarts'
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
  props: {
    filterId: {
      type: Object
    }
  },
  components: {
    'v-chart': ECharts
  },
  watch: {
    filterId: {
      handler () {
        this.loadData()
      },
      deep: true
    }
  },
  data () {
    return {
      seriesData: [{
        name: 'total',
        type: 'line',
        symbolSize: 6,
        hoverAnimation: false,
        data: [],
        xAxisIndex: 0,
        yAxisIndex: 0
      }, {
        name: 'detail',
        type: 'line',
        symbolSize: 6,
        hoverAnimation: false,
        data: [],
        markLine: {
          data: []
        },
        xAxisIndex: 1,
        yAxisIndex: 1
      }],
      xAxis: [{
        gridIndex: 0,
        type: 'category',
        data: [],
        splitLine: {
          show: true
        },
        axisLabel: {
          formatter: (value) => {
            return moment(value).format('DD-MM-YYYY')
          }
        }
      }, {
        gridIndex: 1,
        type: 'category',
        data: [],
        splitLine: {
          show: true
        },
        axisLabel: {
          formatter: (value) => {
            return moment(value).format('DD-MM-YYYY')
          }
        }
      }]
    }
  },
  computed: {
    options () {
      return {
        title: {
          text: `Timeseries at filterid: ${this.filterId.name}`,
          left: 'left'
        },
        legend: {
          data: ['total', 'detail']
        },
        series: [],
        xAxis: [{
          gridIndex: 0,
          type: 'category',
          nameLocation: 'middle',
          data: [],
          splitLine: {
            show: true
          }
        }, {
          gridIndex: 1,
          type: 'category',
          nameLocation: 'middle',
          nameGap: 30,
          data: [],
          splitLine: {
            show: true
          }
        }],
        yAxis: [{
          gridIndex: 0,
          type: 'value',
          splitLine: {
            show: true
          }
        }, {
          gridIndex: 1,
          type: 'value',
          splitLine: {
            show: true
          }
        }],
        brush: {
          xAxisIndex: [0],
          brushLink: [0],
          outOfBrush: {
            colorAlpha: 0.1
          }
        },
        toolbox: {
          feature: {
            brush: {
              title: 'Markeer tijdvlak',
              type: ['lineX', 'clear'],
              xAxisIndex: 0
            }
          }
        },
        grid: [
          {
            left: '10%',
            right: '8%',
            height: '20%'
          },
          {
            left: '10%',
            right: '8%',
            top: '40%',
            height: '50%'
          }
        ]
      }
    }
  },
  mounted () {
    const chart = this.$refs.echart.chart
    this.startBrushing()
    chart.on('brushend', (e) => {
      const index = e.areas[0].coordRange
      this.loadData(this.xAxis[0].data[index[0]], this.xAxis[0].data[index[1]])
    })
    this.loadData()
  },
  methods: {
    ...mapMutations(['setTotalFilterDetails']),
    startBrushing () {
      const chart = this.$refs.echart.chart
      chart.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'brush',
        brushOption: {
          brushType: 'lineX',
          brushMode: 'single'
        }
      })
    },
    loadData (start = null, end = null) {
      const chart = this.$refs.echart.chart
      // Load timeseries
      let url = `${process.env.VUE_APP_API_URL}/measurements/${this.filterId.id}?`
      if (start) {
        url = `${url}start_time=${start}&`
      }
      if (end) {
        url = `${url}end_time=${end}`
      }
      fetch(url)
        .then((res) => {
          return res.json()
        })
        .then((response) => {
          if (!start && !end) {
            const serie = this.seriesData.find(serie => serie.name === 'total')
            serie.data = response.values
            this.xAxis[0].data = response.times
            this.setTotalFilterDetails(response.details)
            this.xAxis[1].data = []
            const serie2 = this.seriesData.find(serie => serie.name === 'detail')
            serie2.data = []
            serie2.markLine.data = []
          } else {
            const serie = this.seriesData.find(serie => serie.name === 'detail')
            serie.data = response.values
            this.xAxis[1].data = response.times
            serie.markLine.data = []
            const markLine = serie.markLine.data
            markLine.push({
              name: 'Maximum [m] NAP',
              yAxis: response.details.max_gws_m_nap,
              label: {
                formatter: (value) => {
                  return `Max: ${value.value}`
                }
              },
              lineStyle: {
                color: '#aca296'
              }
            })
            markLine.push({
              name: 'Minimum [m] NAP',
              yAxis: response.details.min_gws_m_nap,
              label: {
                formatter: (value) => {
                  return `Min: ${value.value}`
                }
              },
              lineStyle: {
                color: '#aca296'
              }
            })
            markLine.push({
              name: 'Gemiddelde [m] NAP',
              yAxis: response.details.gemiddelde_gws_m_nap,
              label: {
                formatter: (value) => {
                  return `Gem: ${value.value}`
                }
              },
              lineStyle: {
                color: '#aca296'
              }
            })
          }
          this.xAxis[1].name = response.time_header

          chart.setOption({
            series: this.seriesData,
            xAxis: this.xAxis,
            yAxis: [{
              name: response.values_header,
              nameLocation: 'middle',
              nameGap: 30,
              gridIndex: 0,
              type: 'value',
              splitLine: {
                show: true
              }
            }, {
              name: response.values_header,
              nameLocation: 'middle',
              gridIndex: 1,
              type: 'value',
              splitLine: {
                show: true
              }
            }]
          })
          this.startBrushing()
        })
    }
  }
}
</script>

<style scoped>
.echart {
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 1000px;
}
</style>
