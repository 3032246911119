<template>
  <div class="table">
    <v-data-table
      dense
      :headers="tableHeaders"
      :items="tableItems"
      ref="datatable"
      width="50%"
      :hide-default-footer="hideDefaultFooter"
      :items-per-page="6"
      :footer-props="{
        itemsPerPageText: 'Rij per pagina'
      }"
    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">{{ props.item.name }}</td>
        <td class="text-xs-left">{{ props.item.value }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    tableHeaders: {
      required: true,
      type: Array
    },
    tableItems: {
      required: true,
      type: Array
    },
    hideDefaultFooter: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
.table {
  width: 100%;
}
</style>
