<template>
  <div id="legend-div">
    <div class="mapboxgl-ctrl-bottom-left pl-2 pb-8" id="legend">
      <div v-for="layer in $store.state.dataLayers" :key="layer.name">
        <div v-for="legend in layer.texticonlegend" :key="legend.name" class="bar-wrapper">
          <span
            class="colored-striped-span"
            :style="legend.style"
          >{{ legend.texticon }}</span>
          <label class="ma-1">{{ legend.name }}</label>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'VLegend',
  inject: ['getMap'],
  data () {
    return {
      map: null
    }
  },
  methods: {
    deferredMountedTo (map) {
      this.map = map
    }
  }
}
</script>

<style>
#legend-div {
  width: 100%;
}
.bar {
  width: 100%;
  height: 10px;
}
.bar-wrapper {
  display: block;
  width: 100%;
  /* margin-bottom: 10px; */
}
.bartext {
  width: 100%;
  display: inline-block;
  text-align: justify;
  text-align-last: justify
}
.colored-span {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.colored-striped-span {
  width: 10px;
  height: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
