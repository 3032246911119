import Vue from 'vue'
import VueRouter from 'vue-router'
import Results from '@/views/Results'
import Information from '@/views/Information'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Information',
    component: Information
  }, {
    path: '/results',
    name: 'Results',
    component: Results
  }
]

const router = new VueRouter({
  routes
})

export default router
