<template>
  <v-app>
    <v-app-bar dense app clipped-left>
      <v-toolbar-title>A27 Utrecht</v-toolbar-title>
      <v-spacer />
      <deltares-logo class="logo" />
    </v-app-bar>
    <v-main class="main d-flex flex-column">
      <map-component class="map-container" />
      <div class="results-container">
        <v-tabs
          align-with-title
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab to="/information">
            Informatie
          </v-tab>
          <v-tab to="/results">
            Details
          </v-tab>
        </v-tabs>
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import MapComponent from '@/components/MapComponent'
import DeltaresLogo from '@/assets/DeltaresLogo.svg'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    MapComponent,
    DeltaresLogo
  },
  mounted () {
    this.loadDataLayers()
    this.loadLocations()
  },
  methods: {
    ...mapActions([
      'loadLocations',
      'loadDataLayers'
    ])
  }
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main {
  height: 100vh;
}

.map-container {
  height: 30%;
}

.results-container {
  height: 70%;
}

.logo {
  height: 100%;
}
</style>
