<template>
  <div class="results">
    <v-row class="ma-0">
      <v-col
        cols="12"
        md="4"
      >
        <data-table v-if="tableItemsLocation.length > 0" :tableHeaders="tableHeaders('Details meetlocatie')" :tableItems="tableItemsLocation" :hideDefaultFooter="false" class="mx-3" />
        <span v-else>Selecteer een meetlocatie op de kaart voor details. </span>

        <div v-if="tableItemsLocation.length > 0">
          <v-select
            :items="locationDetails.peilfilters"
            label="Selecteer Peilfilter op dit meetpunt"
            item-value="id"
            item-text="name"
            v-model="filterId"
            dense
            return-object
            class="ma-3"
          ></v-select>
          <v-row v-if="filterIdEmpty" >
            <v-col cols="6">
              <data-table :tableHeaders="tableHeaders(`Details peilfilter ${filterId.name}`)" :tableItems="tableItemsFilters[page - 1]" class="mx-3" />
                <div class="text-center">
                  <v-pagination
                    v-if="tableItemsFilters.length !== 1"
                    v-model="page"
                    :length="tableItemsFilters.length"
                  ></v-pagination>
                </div>
            </v-col>
            <v-col cols="6">
              <data-table :tableHeaders="tableHeaders(`Details metingen ${filterId.name}`)" :tableItems="tableItemsFilterTotal" class="mx-3" />
            </v-col>
          </v-row>
          <span v-else>Selecteer een peilfilter om verdere details te zien. </span>
        </div>
      </v-col>
      <v-col cols="12" md="8" v-if="tableItemsLocation.length > 0">
        <graph-component v-if="filterIdEmpty" :filterId.sync="filterId"/>
        <span v-else>
          Selecteer een peilfilter om een tijdseries te plotten.
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import GraphComponent from '@/components/GraphComponent'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    DataTable,
    GraphComponent
  },
  watch: {
    filterId: {
      handler () {
        this.loadFilterDetails()
      },
      deep: true
    },
    locationDetails: {
      handler () {
        this.filterId = {}
      },
      deep: true
    }
  },
  data () {
    return {
      filterId: {},
      tableItemsFilters: [[]],
      page: 1
    }
  },
  computed: {
    ...mapGetters(['locationDetails', 'totalFilterDetails']),
    filterIdEmpty () {
      return !_.isEmpty(this.filterId)
    },
    tableItemsLocation () {
      const tableItems = []
      const props = _.get(this.locationDetails, 'properties', {})
      Object.entries(props).forEach(val => {
        tableItems.push({
          value: val[1],
          name: val[0].replaceAll('_', ' ')
        })
      })
      return tableItems
    },
    tableItemsFilterTotal () {
      const tableItems = []
      Object.entries(this.totalFilterDetails).forEach(val => {
        if (val[0] === 'meest_recente_gws_datumtijd') {
          val[1] = moment(val[1]).format('DD-MM-YYYY hh:mm')
        }
        tableItems.push({
          value: val[1],
          name: val[0].replaceAll('_', ' ')
        })
      })
      return tableItems
    }
  },
  methods: {
    loadFilterDetails () {
      if (!this.filterId.id) {
        return
      }
      const url = `${process.env.VUE_APP_API_URL}/filters/${this.filterId.id}`
      fetch(url)
        .then(res => {
          return res.json()
        })
        .then(filterDetails => {
          this.page = 1
          const props = _.get(filterDetails, 'data', [])
          this.tableItemsFilters = []
          props.forEach(prop => {
            const tableItems = []
            Object.entries(prop).forEach(val => {
              if (val[0] === 'begindatum' || val[0] === 'aquifer_bepaald_op_datum' || val[0] === 'einddatum') {
                val[1] = moment(val[1]).format('DD-MM-YYYY hh:mm')
                if (!moment(val[1]).isValid()) {
                  val[1] = 'Geen datum beschikbaar'
                }
              }
              tableItems.push({
                value: val[1],
                name: val[0].replaceAll('_', ' ')
              })
            })
            this.tableItemsFilters.push(tableItems)
          })
        })
    },
    tableHeaders (header) {
      return [
        {
          text: header,
          align: 'left',
          sortable: false,
          value: 'name',
          class: 'blue lighten-3'
        },
        {
          align: 'left',
          sortable: false,
          value: 'value',
          class: 'blue lighten-3'
        }
      ]
    }
  }
}
</script>
