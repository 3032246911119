import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    map: null,
    dataLayers: [],
    locations: {},
    locationDetails: [],
    totalFilterDetails: {}
  },
  mutations: {
    setMap (state, map) {
      state.map = map
    },
    setLocations (state, data) {
      const layers = state.dataLayers.find(layer => layer.id === data.id)
      layers.data.push(data)
    },
    setDataLayers (state, dataLayers) {
      state.dataLayers = dataLayers
    },
    setLocationDetails (state, locationDetails) {
      state.locationDetails = locationDetails
    },
    setTotalFilterDetails (state, details) {
      state.totalFilterDetails = details
    }
  },
  actions: {
    loadDataLayers (store) {
      const url = './config/datalayers-config.json'
      fetch(url)
        .then(res => {
          return res.json()
        })
        .then(response => {
          store.commit('setDataLayers', response)
        })
    },
    loadLocations (store) {
      // Retrieve all well locations from the BE
      const url = `${process.env.VUE_APP_API_URL}/locations/`
      fetch(url)
        .then(res => {
          return res.json()
        })
        .then(response => {
          const locationsLayer = {
            id: 'custom-locations',
            type: 'circle',
            source: {
              type: 'geojson',
              data: response
            },
            paint: {
              'circle-color': 'red'
            }
          }
          store.commit('setLocations', locationsLayer)
        })
    },
    loadLocationDetails (store, locationId) {
      // Load details per well locations (retrieve all filters at loc)
      const url = `${process.env.VUE_APP_API_URL}/locations/${locationId}`
      fetch(url)
        .then(res => {
          return res.json()
        })
        .then(response => {
          this.commit('setLocationDetails', response)
        })
    }
  },
  getters: {
    locationDetails (state) {
      return state.locationDetails
    },
    totalFilterDetails (state) {
      return state.totalFilterDetails
    }
  }
})
